import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import Banner from "../../../src/Image/banner-1.jpg";

import merchantImage from "../../../src/Image/merchantImage.png";

import swiggy1 from "../../../src/Image/swiggy-fig-1.jpg";
import swiggy2 from "../../../src/Image/swiggy-fig-2.jpg";
import swiggy3 from "../../../src/Image/swiggy-fig-3.jpg";

import copyIcon from "../../../src/Image/copyIcon.jpg";
import mailIcon from "../../../src/Image/mailIcon.jpg";
import fbMsgIcon from "../../../src/Image/fbMsgIcon.jpg";
import whatsAppIcon from "../../../src/Image/whatsAppIcon.jpg";
import linkedinIcon from "../../../src/Image/linkedinIcon.png";
import { Token_Generator } from "../../services/auth";
import { getBanner, getCampaignPromotion, getMerchantAndLogo } from "../../services/services";
import Loader from "../partial/Loader";

function Offer() {
    const [responseData, setResponseData] = useState([]);
    const [bannerData, setBannerData] = useState([]);
    const [imageData, setImageData] = useState();
    const [loader, setLoader] = useState(true);
    const [merchantIds, setMerchantIds] = useState();
    const subdomain = window.location.hostname.split(".")[0];
    console.log(subdomain);
    useEffect(() => {
        if (subdomain === "compcare") {
            setMerchantIds("MER000261");
        }
    }, [subdomain]);
    console.log(merchantIds);

    const logo = async () => {
        if (merchantIds?.length > 0) {
            setLoader(true);
            const token = await Token_Generator();
            await getMerchantAndLogo(token.access_token, merchantIds).then((value) => {
                setImageData(value[0]?.blobFilePath);
            });
            const responsee = await getCampaignPromotion(token.access_token, merchantIds);
            await getBanner(token.access_token, merchantIds).then((res) => {
                setBannerData(res[0]?.bannerDetails);
                setLoader(false);
            });

            setResponseData(responsee.data);
        }
    };

    useEffect(() => {
        logo();
    }, [merchantIds]);

    console.log(responseData);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    console.log(bannerData);
    const filteredResponse = responseData ? responseData.filter((item) => !Array.isArray(item.tags) || item.tags.length > 0) : [];

    return (
        <>
            {loader && <Loader />}
            <div class="offer-wapper">
                <header className="offer-header">
                    <div className="container">
                        <div className="offer-header__logo">
                            <Link>
                                <img src={imageData} alt="logo" />
                            </Link>
                        </div>
                        <div className="offer-header-login-signup">
                            <ul>
                                <li>
                                    <Link to={`/signin`}>Login</Link>
                                </li>
                                <li>
                                    <Link to={`/signup`} className="signup">
                                        Signup
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
                {/* header end */}
                {/* <div className="share-refe-code">
          <div className="share-refe-code__inside">
            <div className="share-refe-code__inside__left">
              <span className="shareCodeText">Share your Referral Code: REF38272764</span>
              <Link className="shareIconLink">
                <img src={copyIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={mailIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={fbMsgIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={whatsAppIcon} alt="icon" />
              </Link>
              <Link className="shareIconLink">
                <img src={linkedinIcon} alt="icon" />
              </Link>
            </div>
            <div className="share-refe-code__inside__right">
              <span className="loyaltyPnt">Your Loyalty Points</span> <span className="lined">|</span> <strong>760</strong>
            </div>
          </div>
        </div>  */}

                <section className="offer-slider">
                    <Slider {...settings}>
                        {bannerData.map((item, index) => (
                            <div className="offer-slider__item" key={index}>
                                {console.log(item)}
                                <img src={item.blobFilePath} alt={`banner-${index}`} />
                            </div>
                        ))}
                        {/* <div className="offer-slider__item">
              <img src={Banner} alt="Banner 1" />
            </div> */}
                        {/* <div className="offer-slider__item">
              <img src={Banner} alt="Banner 2" />
            </div>
            <div className="offer-slider__item">
              <img src={Banner} alt="Banner 3" />
            </div> */}
                    </Slider>
                </section>

                <div className="offer-mid-container">
                    <div className="container">
                        <div className="section-header">
                            <h2>New Offers</h2>
                            <p>Get Rewards on every service</p>
                        </div>
                        <div className="offer-card-container">
                            {/* <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy1} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div>

                                    <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy2} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div>

                                    <div className="offer-card-item">
                                      <div className="offer-card-item__fig">
                                        <img src={swiggy3} alt="Swiggy" />
                                      </div>
                                      <div className="offer-card-item__content">
                                        <h4>Debit Cards & Credit Cards</h4>
                                        <h6>Swiggy</h6>
                                        <p>Satiate your hunger with a jaw-dropping 15% discount on Swiggy using Federal Bank Cards.</p>
                                        <a href="#" className="btn btn-primary">Learn More</a>
                                      </div>
                                    </div> */}
                            {filteredResponse &&
                                filteredResponse.map((item, ind) => (
                                    <div className="offer-card-item" key={ind}>
                                        <div className="card-item-center-block">
                                            {/* {console.log(item)} */}
                                            <div dangerouslySetInnerHTML={item.description ? { __html: item.description } : null}></div>
                                        </div>
                                        {/* <button onClick={onClick}>Onclick</button> */}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                <footer className="offer-footer">
                    <div className="container">
                        <p className="copyr">© Copyright Compcare Urgent Care. All Rights Reserved.</p>
                        {/* <div className="offer-footer__nav">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/offer">Offer</Link></li>
              </ul>
            </div> */}
                    </div>
                </footer>
            </div>
            {/* offer-wapper end */}
        </>
    );
}

export default Offer;
