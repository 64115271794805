import { baseURL } from "../utils/url";

export const Token_Generator = async () => {
    try {
        const fetchResponse = await fetch(baseURL + "accessToken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const Internal_Token = async (body) => {
    try {
        const fetchResponse = await fetch(baseURL + "internaltoken", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const signIn = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "signin", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const signUp = async (token, data) => {
    try {
        const fetchResponse = await fetch(baseURL + "signup", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const resetPassword = async (token, code, data) => {
    try {
        const fetchResponse = await fetch(baseURL + `resetPassword?code=${code}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const response = await fetchResponse.json();
        return response;
    } catch (error) {
        return error;
    }
};

export const sendEmail = async (body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}email`, {
            method: "POST",
            headers: {
                Authorization: "Bearer ",
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const sendEmailData = async (token, body) => {
    try {
        const fetchResponse = await fetch(`${baseURL}sendGrid`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

// export const getProfileData = async (token, body) => {
//     try {
//         const fetchResponse = await fetch(`${baseURL}getProfile`, {
//             method: "POST",
//             headers: {
//                 Authorization: "Bearer " + token,
//                 Accept: "application/json",
//                 "Content-Type": "application/json",
//             },
//             body: JSON.stringify(body),
//         });
//         return await fetchResponse.json();
//     } catch (error) {
//         return error;
//     }
// };
export const getProfileData = async (token, merchantcode) => {
 
    try {
      
        const fetchResponse = await fetch(baseURL + `getProfile?merchantcode=${merchantcode}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const getMerchantData = async (token, rewardportal) => {
 
    try {
      
        const fetchResponse = await fetch(baseURL + `getMerchantCode?rewardportal=${rewardportal}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};

export const BarCode = async (token,values) => {
   
    try {
       
        const fetchResponse = await fetch(baseURL + `barcode?values=${values}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        const response = await fetchResponse.json();

        return response;
    } catch (error) {
        return error;
    }
};
